<script>
import { get } from 'vuex-pathify'

export default {
	data() {
		return {
			form: {},
			loading: false,
			orders: [],
			validation: null,
			loadingOrders: false,
			btnState: null,
		}
	},
	computed: {
		user: get('shop/user'),
	},
	methods: {
		submitForm() {
			const { file, ...data } = this.form
			this.$shopApi.post({
				url: '/send-repentance-email',
				data,
				files: { file },
				loading: (v) => (this.loading = v),
				onValidation: ({ validation }) => (this.validation = validation),
				onSuccess: () => {
					this.form = {}
					this.btnState = 'success'
				},
				onError: (data) => {
					this.btnState = 'error'
				},
			})
		},
		setUserInfo() {
			this.form.fullName = this.user.firstname + ' ' + this.user.lastname
			this.form.document = this.user.document
			this.form.email = this.user.contactEmail || this.user.accountEmail
			this.form.phone = this.user.phoneAreaCode + this.user.phoneNumber
		},
		async setOrders() {
			this.$shopApi.get({
				url: '/user/orders',
				loading: (v) => (this.loadingOrders = v),
				onSuccess: ({ data }) => {
					if (!data.orders.length) return
					this.orders = data.orders.map((order) => {
						const { buyerName, name } = order.statusesInfo.title
						const total = this.$options.filters.price(order.total)
						const text = `Pedido #${order.code} - ${total} (${buyerName})`
						return { text, value: order.id }
					})
					this.form.orderId = this.orders[this.orders.length - 1].value
				},
			})
		},
	},
	watch: {
		user(user) {
			if (!user) {
				this.orders = []
				this.form = {}
			} else {
				this.setUserInfo()
				this.setOrders()
			}
		},
	},
	created() {
		if (this.user) {
			this.setUserInfo()
			this.setOrders()
		}
	},
}
</script>

<template>
	<v-container style="max-width: 1024px" class="my-12">
		<h1 class="font-4">Arrepentimiento</h1>
		<Validator :validation="validation">
			<v-form @submit.prevent="submitForm">
				<TextField label="Nombre completo" v-model="form.fullName" class="mb-6" />
				<TextField label="DNI/CUIT" v-model="form.document" type="number" class="mb-6" />
				<TextField label="Correo electrónico" v-model="form.email" class="mb-6" />
				<TextField label="Teléfono o Whatsapp" v-model="form.phone" type="number" class="mb-6" />
				<template v-if="user && orders.length">
					<Select
						:items="orders"
						:loading="loadingOrders"
						label="Número de pedido"
						v-model="form.orderId"
						class="mb-6"
						no-data-text="No hay pedidos"
					/>
				</template>
				<TextField label="Número de pedido" v-model="form.orderId" class="mb-6" v-else />
				<Select
					:items="[
						'No recibe la nota de crédito en mi tarjeta de crédito/débito',
						'Mi producto llegó dañado/rayado/incompleto',
						'No recibí la factura',
						'Arrepentimiento de compra',
						'Otros reclamos',
					]"
					label="Motivo de la consulta"
					v-model="form.reason"
					class="mb-6"
				/>
				<TextField
					label="Ingresa el motivo de tu consulta"
					v-model="form.customReason"
					class="mb-6"
					v-if="form.reason === 'Otros reclamos'"
				/>
				<TextArea label="Mensaje" v-model="form.message" class="mb-6" />
				<v-file-input label="Archivo" v-model="form.file" class="mb-6" />
				<MagicButton type="submit" v-model="btnState" x-large :loading="loading">
					Enviar
					<template #success> <v-icon>mdi-check</v-icon> Listo! </template>
					<template #error> Oops! Revisa el formulario </template>
				</MagicButton>
			</v-form>
		</Validator>
	</v-container>
</template>
